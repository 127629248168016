<script setup>
import { ref, watchEffect } from 'vue'
import emblaCarouselVue from 'embla-carousel-vue'
import emblaCarouselAutoplay from 'embla-carousel-autoplay'

const props = defineProps({
  loop: {
    type: Boolean,
    default: true,
  },
  speed: {
    type: Number,
    default: 7,
  },
})

const [emblaNode, emblaApi] = emblaCarouselVue({ loop: props.speed, speed: props.speed }, [
  emblaCarouselAutoplay({ stopOnInteraction: false, stopOnMouseEnter: true }),
])

const dots = ref([])
const currentIndex = ref(1)

watchEffect(() => {
  if (emblaApi.value) {
    dots.value = emblaApi.value.scrollSnapList()

    emblaApi.value.on('select', () => {
      currentIndex.value = emblaApi.value.selectedScrollSnap() + 1
    })
  }
})
</script>

<template>
  <div
    ref="emblaNode"
    class="relative overflow-hidden"
  >
    <div class="relative flex h-auto flex-row">
      <slot />
    </div>
    <div class="my-10 flex justify-center space-x-2">
      <span
        v-for="(dot, index) in dots"
        :key="index"
        class="h-4 w-4 rounded-full"
        :class="[index === currentIndex - 1 ? 'bg-navy' : 'bg-gray-300']"
      ></span>
    </div>
  </div>
</template>

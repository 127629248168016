<script setup>
import { ref } from 'vue'
import Link from '@/Components/Links/Link.vue'
import SiteNavChevronDownIcon from '@svgs/site-nav-chevron-down.svg'
import {
  TransitionRoot,
  Dialog,
  TransitionChild,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { XMarkIcon } from '@heroicons/vue/24/outline'

defineProps({
  items: Array,
})

let openMobileNavigation = ref(false)
</script>

<template>
  <TransitionRoot
    as="template"
    :show="openMobileNavigation"
  >
    <Dialog
      as="div"
      class="relative z-40 lg:hidden"
      @close="openMobileNavigation = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 z-40 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
            <div class="flex px-4 pb-2 pt-5">
              <button
                type="button"
                class="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                @click="openMobileNavigation = false"
              >
                <span class="sr-only">Close menu</span>
                <XMarkIcon
                  class="h-6 w-6"
                  aria-hidden="true"
                />
              </button>
            </div>

            <!-- Links -->
            <Disclosure
              v-for="item in items"
              :key="item.title"
              v-slot="{ open }"
            >
              <DisclosureButton
                class="flex w-full justify-between px-4 py-2 text-left text-base font-medium hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
              >
                <span>{{ item.title }}</span>
                <ChevronDownIcon
                  :class="open ? 'rotate-180 transform' : ''"
                  class="h-5 w-5 text-black"
                />
              </DisclosureButton>
              <DisclosurePanel class="px-4 pb-6 text-sm text-gray-500">
                <Disclosure
                  v-for="childItem in item.children"
                  :key="childItem.title"
                  v-slot="{ open }"
                >
                  <DisclosureButton
                    class="flex w-full justify-between px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                  >
                    <Link :href="childItem.url">
                      <span>{{ childItem.title }}</span>
                    </Link>
                  </DisclosureButton>
                  <hr class="my-0 h-px border-0 bg-gray-200" />
                </Disclosure>
              </DisclosurePanel>
              <hr class="my-0 h-px border-0 bg-gray-200" />
            </Disclosure>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <div
    class="ml-auto cursor-pointer lg:hidden"
    @click="openMobileNavigation = true"
  >
    <img
      src="/images/menu.png"
      srcset="/images/menu@2x.png 2x"
      alt="Image of the mobile menu icon"
      class="lg:hidden"
    />
  </div>
  <div class="ml-8 hidden space-x-4 lg:inline-block xl:ml-16 xl:space-x-10">
    <a
      v-for="item in items"
      :key="item.title"
      class="inline-block border-navy font-semibold text-navy focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
      :class="{ 'border-b-[3px]': item.is_current || item.is_parent }"
      :href="item.url"
    >
      <span class="border-b-3 flex items-center border-navy">
        {{ item.title }}
        <SiteNavChevronDownIcon
          v-if="item.children.length > 0"
          class="ml-3 text-navy transition duration-150 ease-in-out group-hover:text-opacity-80"
          aria-hidden="true"
        />
      </span>
    </a>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  items: Array,
})

const secondaryNavigation = computed(() => {
  return props.items && props.items.find((item) => (item.is_current && item.children.length > 0) || item.is_parent)
})
</script>

<template>
  <div
    v-if="secondaryNavigation"
    class="hidden bg-navy py-4 lg:block"
  >
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="space-x-12">
        <a
          v-for="item in secondaryNavigation.children"
          :key="item.title"
          class="inline-flex items-center border-b-white font-medium text-white focus:bg-yellow-500 focus:text-black focus:outline-none"
          :class="{ 'border-b-[3px]': item.is_current, 'focus:border-b-black': item.is_current }"
          :href="item.url"
        >
          {{ item.title }}
        </a>
      </div>
    </div>
  </div>
</template>

export const setQueryParam = (paramName, paramValue) => {
  const queryParams = new URLSearchParams(window.location.search)
  queryParams.set(paramName, paramValue)
  history.replaceState(null, null, `?${queryParams.toString()}`)
}

export const setPaginationLinkQueryParams = () => {
  const paginationLinks = document.querySelectorAll('a.pagination-link[href]')

  paginationLinks.forEach((link) => {
    link.href = mergeLinkQueryParamsWithCurrentLink(link.href, 'Page')
  })
}

const mergeLinkQueryParamsWithCurrentLink = (linkHref, excludeKey = '') => {
  const linkUrl = new URL(linkHref, window.location.href)
  const currentUrl = new URL(window.location.href)

  currentUrl.searchParams.forEach((value, key) => {
    if (!linkUrl.searchParams.has(key) || !key.includes(excludeKey)) {
      linkUrl.searchParams.set(key, value)
    }
  })

  return linkUrl.toString()
}

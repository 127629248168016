<script setup>
import { setQueryParam, setPaginationLinkQueryParams } from '@/Helpers/urlHelper.js'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { onMounted } from 'vue'
const props = defineProps({
  tabs: Array,
  selectedTabIndex: String,
})

const changeTab = (tab) => {
  setQueryParam('tabIndex', tab)
  setTimeout(() => {
    setPaginationLinkQueryParams()
  }, 100)
}

onMounted(() => {
  changeTab(props.selectedTabIndex)
})
</script>

<template>
  <TabGroup
    :default-index="parseInt(selectedTabIndex)"
    @change="changeTab"
  >
    <TabList class="my-8 rounded-xl sm:flex sm:space-x-4">
      <Tab
        v-for="tab in tabs"
        :key="tab.key"
        v-slot="{ selected }"
        as="template"
      >
        <button
          :class="[
            'w-full rounded-lg p-3.5 font-coheadline text-base font-bold leading-5 text-black md:w-auto md:text-2xl',
            'ring-yellow focus:outline-none focus:ring-2',
            selected ? 'bg-navy text-white' : 'hover:bg-navy hover:text-white',
          ]"
        >
          {{ tab.title }}
          <span class="hidden md:inline">({{ tab.count }})</span>
        </button>
      </Tab>
    </TabList>

    <TabPanels class="mt-2">
      <TabPanel
        v-for="tab in tabs"
        :key="tab.key"
        :class="['rounded-xl bg-white py-3', 'ring-yellow focus:outline-none focus:ring-2']"
      >
        <slot :name="`${tab.key}-tab`"></slot>
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

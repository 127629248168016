import './bootstrap'

import * as Sentry from '@sentry/vue'

import dayjs from 'dayjs'
import dayjsCustomParseFormatPlugin from 'dayjs/plugin/customParseFormat'
import dayjsDurationPlugin from 'dayjs/plugin/duration'

import { createApp } from 'vue'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import { Ziggy } from './ziggy'

import SiteHeader from '@/Layouts/SiteHeader.vue'
import SiteLeftNavigation from '@/Layouts/Navigation/SiteLeftNavigation.vue'
import SiteRightNavigation from '@/Layouts/Navigation/SiteRightNavigation.vue'
import SiteSecondaryNavigation from '@/Layouts/Navigation/SiteSecondaryNavigation.vue'
import BetaBanner from '@/Components/BetaBanner.vue'
import ShowMoreList from '@/Components/ShowMoreList.vue'
import CalendarViewMorePopover from '@/Components/Popovers/CalendarViewMorePopover.vue'
import Concertina from '@/Fieldsets/Concertina.vue'
import HeroCarousel from '@/Fieldsets/HeroCarousel.vue'
import TestimonialCarousel from '@/Fieldsets/TestimonialCarousel.vue'
import BeWellSearchResultTabs from '@/Components/Tabs/BeWellSearchResultTabs.vue'
import BeWellSearchResultSubTabs from '@/Components/Tabs/BeWellSearchResultSubTabs.vue'
import OrganisationMapMultipleMarker from '@/Components/Maps/OrganisationMapMultipleMarker.vue'
import OrganisationMapSingleMarker from '@/Components/Maps/OrganisationMapSingleMarker.vue'

dayjs.extend(dayjsCustomParseFormatPlugin).extend(dayjsDurationPlugin)

const app = createApp({
  components: {
    SiteHeader,
    SiteLeftNavigation,
    SiteRightNavigation,
    SiteSecondaryNavigation,
    BetaBanner,
    ShowMoreList,
    CalendarViewMorePopover,
    Concertina,
    HeroCarousel,
    TestimonialCarousel,
    BeWellSearchResultTabs,
    BeWellSearchResultSubTabs,
    OrganisationMapMultipleMarker,
    OrganisationMapSingleMarker,
  },
})
  .use(ZiggyVue, Ziggy)
  .use(dayjs)

app.config.globalProperties.$date = dayjs
app.config.globalProperties.$pluralize = (amount, singular, plural) => (amount > 1 || amount === 0 ? plural : singular)

if (import.meta.env.VITE_APP_ENV != 'local') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    environment: import.meta.env.VITE_APP_ENV,
  })
}

app.mount('#public-app')

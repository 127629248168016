import { computed, ref } from 'vue'

export function usePagination(items, itemsPerPage = 3) {
  const currentPage = ref(1)

  const totalPages = computed(() => Math.ceil(items.length / itemsPerPage))

  const paginatedItems = computed(() => {
    const start = (currentPage.value - 1) * itemsPerPage
    const end = start + itemsPerPage
    return items.slice(start, end)
  })

  const nextPage = () => {
    if (currentPage.value < totalPages.value) {
      currentPage.value++
    }
  }

  const prevPage = () => {
    if (currentPage.value > 1) {
      currentPage.value--
    }
  }

  return {
    paginatedItems,
    currentPage,
    totalPages,
    nextPage,
    prevPage,
  }
}

<script setup>
import { ref } from 'vue'
import { GoogleMap, MarkerCluster } from 'vue3-google-map'
import { SuperClusterAlgorithm } from '@googlemaps/markerclusterer'
import OrganisationMapCustomMarker from '@/Components/Maps/OrganisationMapCustomMarker.vue'
import OrganisationMapInfoWindow from '@/Components/Maps/OrganisationMapInfoWindow.vue'

const props = defineProps({
  locality: {
    type: String,
    default: '',
  },
  service: {
    type: String,
    default: '',
  },
})

const apiKey = import.meta.env.VITE_GOOGLE_API_KEY
const DEFAULT_ZOOM = 11
const MIN_ZOOM = 11
const MAX_ZOOM = 14

const organisations = ref([])
const showInfoWindow = ref(false)
const selectedMarker = ref(null)

axios
  .get('/api/statamic/organisations', { params: { locality: props.locality, service: props.service } })
  .then((response) => {
    organisations.value = response.data
  })

const algorithm = new SuperClusterAlgorithm({
  radius: 120,
  minZoom: MIN_ZOOM,
  maxZoom: MAX_ZOOM,
  log: false,
  generateId: false,
  minPoints: 2,
})

const renderer = ref({
  render: ({ count, position, bounds }, stats, map) => {
    return new google.maps.Marker({
      position,
      icon: {
        url: `/images/cluster-marker.png`,
        scaledSize: new google.maps.Size(56, 56),
      },
      label: {
        text: String(count),
        color: 'white',
        FontFace: 'co-headline',
        fontSize: '18px',
        fontWeight: 'bold',
      },
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    })
  },
})

const onClusterClick = (e, cluster, map) => {
  if (map.getZoom() === MAX_ZOOM) {
    const orgIds = cluster.markers.map((marker) => marker.opts.orgId)
    if (orgIds.length > 0) {
      selectedMarker.value = {
        position: cluster._position,
        organisations: organisations.value.filter((organisation) => orgIds.includes(organisation.id)),
      }

      showInfoWindow.value = true
    }
  }

  map.fitBounds(cluster.bounds)
}

const onMarkerClick = (event, organisation) => {
  const { e, marker } = event

  e.stopPropagation()

  selectedMarker.value = {
    position: { lat: parseFloat(organisation.latitude), lng: parseFloat(organisation.longitude) },
    organisations: [organisation],
  }

  showInfoWindow.value = true
  marker.map.panTo(marker.getPosition())
}
</script>

<template>
  <GoogleMap
    :api-key="apiKey"
    class="h-[650px] w-full"
    :center="{ lat: 53.52239067051572, lng: -1.1284376516327974 }"
    :zoom="DEFAULT_ZOOM"
    :min-zoom="MIN_ZOOM"
    :max-zoom="MAX_ZOOM"
  >
    <MarkerCluster :options="{ algorithm: algorithm, renderer: renderer, onClusterClick: onClusterClick }">
      <OrganisationMapInfoWindow
        v-if="showInfoWindow && selectedMarker"
        :position="selectedMarker.position"
        :organisations="selectedMarker.organisations"
        @close="showInfoWindow = false"
      />
      <OrganisationMapCustomMarker
        v-for="organisation in organisations"
        :key="organisation.id"
        :center="{ lat: parseFloat(organisation.latitude), lng: parseFloat(organisation.longitude) }"
        :org-id="organisation.id"
        :avatar="organisation.avatar"
        :initials="organisation.initials"
        @click="onMarkerClick($event, organisation)"
      />
    </MarkerCluster>
  </GoogleMap>
</template>

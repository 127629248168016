<script setup>
import { GoogleMap } from 'vue3-google-map'
import OrganisationMapCustomMarker from './OrganisationMapCustomMarker.vue'

defineProps({
  center: {
    type: Object,
    required: true,
  },
  zoom: {
    type: Number,
    default: 15,
  },
  avatar: {
    type: String,
  },
  initials: {
    type: String,
  },
})

const apiKey = import.meta.env.VITE_GOOGLE_API_KEY
</script>

<template>
  <GoogleMap
    :api-key="apiKey"
    class="h-[475px] w-full"
    :center="center"
    :zoom="zoom"
  >
    <OrganisationMapCustomMarker
      :center="center"
      :avatar="avatar"
      :initials="initials"
    />
  </GoogleMap>
</template>

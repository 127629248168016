<script setup>
import { CustomMarker } from 'vue3-google-map'
import OrganisationAvatar from '@/Components/Maps/OrganisationAvatar.vue'
import { ref } from 'vue'

defineProps({
  center: {
    type: Object,
    required: true,
  },
  orgId: {
    type: Number,
  },
  avatar: {
    type: String,
  },
  initials: {
    type: String,
  },
})

const customMarker = ref(null)

defineEmits(['click'])
</script>

<template>
  <CustomMarker
    ref="customMarker"
    :options="{ position: center, anchorPoint: 'BOTTOM_CENTER', orgId: orgId }"
    @click="$emit('click', { e: $event, marker: customMarker.customMarker })"
  >
    <OrganisationAvatar
      :avatar="avatar"
      :initials="initials"
    />
  </CustomMarker>
</template>

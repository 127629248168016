<script setup>
import { InfoWindow } from 'vue3-google-map'
import Link from '@/Components/Links/Link.vue'
import CloseButton from '@/Components/Buttons/CloseButton.vue'
import OrganisationAvatar from '@/Components/Maps/OrganisationAvatar.vue'
import { usePagination } from '@/Composables/usePagination.js'

defineEmits(['close'])

const props = defineProps({
  position: {
    type: Object,
    required: true,
  },
  organisations: {
    type: Array,
    default: () => [],
  },
})

const getServicesText = (services) => {
  if (services.length > 0) {
    let text = services[0]

    if (services.length > 1) {
      text += ` +${services.length - 1} more`
    }

    return text
  }

  return 'No services added'
}

const { paginatedItems, currentPage, totalPages, nextPage, prevPage } = usePagination(props.organisations)
</script>

<template>
  <InfoWindow
    :options="{ position: position, pixelOffset: { height: -56 } }"
    @closeclick="$emit('close')"
  >
    <div class="absolute right-0 top-0 z-10 h-5 w-5 bg-white"></div>
    <CloseButton
      class="absolute right-0 top-2 z-20"
      @click="$emit('close')"
    />
    <div class="flex flex-col items-center divide-y divide-gray-200 rounded-md p-4 md:w-[570px]">
      <div
        v-for="organisation in paginatedItems"
        :key="organisation.id"
        class="flex w-full flex-col py-4 md:flex-row md:items-center"
      >
        <div class="flex items-center">
          <OrganisationAvatar
            :avatar="organisation.avatar"
            :initials="organisation.initials"
            :include-border-and-shadow="false"
            class="self-start md:self-center"
          />

          <div class="ml-4">
            <h4 class="truncate text-base font-bold">
              {{ organisation.title }}
            </h4>

            <p class="text-sm md:text-base">{{ getServicesText(organisation.services) }}</p>

            <Link
              class="block text-base font-medium md:ml-auto md:mt-0 md:hidden"
              :href="`/organisations/${organisation.slug}`"
            >
              View profile
            </Link>
          </div>
        </div>

        <Link
          class="ml-auto hidden text-base font-medium md:block"
          :href="`/organisations/${organisation.slug}`"
        >
          View profile
        </Link>
      </div>
      <div
        v-if="organisations.length > 3"
        class="flex w-full justify-between border-t border-gray-200 pt-4"
      >
        <button
          :disabled="currentPage === 1"
          class="text-base font-medium text-blue underline decoration-1 underline-offset-2 hover:decoration-2 focus:bg-yellow-500 focus:decoration-2 focus:outline-none disabled:text-gray-400 disabled:no-underline"
          @click="prevPage"
        >
          Previous
        </button>
        <button
          :disabled="currentPage === totalPages"
          class="text-base font-medium text-blue underline decoration-1 underline-offset-2 hover:decoration-2 focus:bg-yellow-500 focus:decoration-2 focus:outline-none disabled:text-gray-400 disabled:no-underline"
          @click="nextPage"
        >
          Next
        </button>
      </div>
    </div>
  </InfoWindow>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import emblaCarouselVue from 'embla-carousel-vue'
import emblaCarouselAutoplay from 'embla-carousel-autoplay'

const props = defineProps({
  loop: {
    type: Boolean,
    default: true,
  },
  speed: {
    type: Number,
    default: 7,
  },
  active: {
    type: Boolean,
    default: true,
  },
  progress: {
    type: Boolean,
    default: true,
  },
  theme: {
    type: String,
    default: 'well_doncaster',
  },
})

const [emblaNode, emblaApi] = emblaCarouselVue({ loop: props.speed, speed: props.speed, active: props.active }, [
  emblaCarouselAutoplay({ stopOnInteraction: false, stopOnMouseEnter: true }),
])

const dots = ref([])
const currentIndex = ref(1)

watchEffect(() => {
  if (emblaApi.value) {
    dots.value = emblaApi.value.scrollSnapList()

    emblaApi.value.on('select', () => {
      currentIndex.value = emblaApi.value.selectedScrollSnap() + 1
    })

    setTimeout(() => {
      emblaApi.value.reInit()
    }, 5000)
  }
})
</script>

<template>
  <div
    ref="emblaNode"
    class="relative overflow-hidden"
  >
    <div class="relative flex">
      <slot />
    </div>
    <div
      v-if="progress && active"
      class="absolute right-0 top-0 hidden md:block"
    >
      <span>{{ currentIndex }} of {{ dots.length }}</span>
      <div class="relative h-2.5 w-[92px] overflow-hidden rounded-full">
        <div class="absolute h-full w-full bg-gray-100"></div>
        <div
          class="relative h-full w-0 rounded-full transition-all duration-300 ease-out"
          :class="[theme === 'be_well' ? 'bg-yellow' : 'bg-mint']"
          :style="{ width: `${(currentIndex / dots.length) * 100}%` }"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { setQueryParam } from '@/Helpers/urlHelper.js'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { CalendarIcon, ListBulletIcon, MapIcon } from '@heroicons/vue/24/outline'
import { computed, onMounted } from 'vue'

const props = defineProps({
  includeTabs: Array,
  selectedTabIndex: String,
  queryParamName: String,
})

const availableTabs = [
  {
    title: 'List',
    key: 'list',
    icon: ListBulletIcon,
  },
  {
    title: 'Calendar',
    key: 'calendar',
    icon: CalendarIcon,
  },
  {
    title: 'Map',
    key: 'map',
    icon: MapIcon,
  },
]

const tabs = computed(() => {
  return availableTabs.filter((tab) => {
    return props.includeTabs.includes(tab.key)
  })
})

const changeTab = (tab) => {
  setQueryParam(props.queryParamName ?? 'subTabIndex', tab)
}

onMounted(() => {
  changeTab(props.selectedTabIndex)
})
</script>

<template>
  <TabGroup
    :default-index="parseInt(selectedTabIndex)"
    @change="changeTab"
  >
    <TabList class="flex space-x-4 rounded-xl">
      <Tab
        v-for="tab in tabs"
        :key="tab.key"
        v-slot="{ selected }"
        as="template"
      >
        <button
          :class="[
            'ml-auto flex items-center rounded-lg p-2.5 text-black',
            'ring-yellow focus:outline-none focus:ring-2',
            selected ? 'bg-gray-100' : 'hover:bg-gray-100',
          ]"
        >
          <component
            :is="tab.icon"
            class="mr-2 h-6 w-6 text-navy"
          />
          {{ tab.title }}
        </button>
      </Tab>
    </TabList>

    <TabPanels class="mt-2">
      <TabPanel
        v-for="tab in tabs"
        :key="tab.key"
        :class="['rounded-xl bg-white py-3', 'ring-yellow focus:outline-none focus:ring-2']"
      >
        <slot :name="`${tab.key}-tab`"></slot>
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>
